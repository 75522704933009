import styled from 'styled-components';

const TopSectionStyle = styled.div`
  .background {
    width: 100%;
    height: 89vh;
    opacity: 0.1;
    object-fit: cover;
    animation-name: background-opacity;
    animation-duration: 1s;
  }

  @keyframes background-opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.1;
    }
  }

  .content {
    position: absolute;
    top: 45vh;
    z-index: 999;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @keyframes slogan-opacity {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .slogan {
    width: 100%;
    text-align: center;
    font-size: 52px;
    font-weight: 400;
    animation-name: slogan-opacity;
    animation-duration: 1s;
  }

  @keyframes subslogan-opacity {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .subslogan {
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    animation-name: subslogan-opacity;
    animation-duration: 1s;
    animation-fill-mode: backwards;
    animation-delay: 0.3s;
  }

  .buttons {
    display: flex;
    margin: 20px;
    animation-name: buttons-opacity;
    animation-duration: 1s;
    animation-delay: 0.6s;
    animation-fill-mode: backwards;
  }

  @keyframes buttons-opacity {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default TopSectionStyle;
