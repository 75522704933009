import {Component} from 'react';

import Header from 'components';

import NotFoundStyle from './NotFound.style';
import {ReactComponent as NotFound404} from './images/not_found.svg';

class NotFound extends Component {
  render() {
    return (
      <NotFoundStyle>
        <div className="header">
          <Header />
        </div>
        <div className="content">
          <NotFound404 style={{width: '50%', height: '50%', margin: '100px'}} />
          The page you are looking for does not exist!
        </div>
      </NotFoundStyle>
    );
  }
}

export default NotFound;
