import {Component} from 'react';

import Header from 'components';

import ServicesStyle from './Services.style';
import Section from './components';
import {ReactComponent as Analysis} from './images/analysis.svg';
import {ReactComponent as Team} from './images/team.svg';
import {ReactComponent as Technology} from './images/technology.svg';
import {ReactComponent as Cost} from './images/cost.svg';

class Services extends Component {
  render() {
    return (
      <ServicesStyle>
        <div className="header">
          <Header />
        </div>
        <div className="cabecita">Just relax, we take it from here</div>
        <div className="content">
          <Section
            text={{
              title: 'Taylored analysis',
              content:
                'Need help designing a profitable business plan? Having trouble improving revenue? Tired of legacy systems and technologies? Looking to provide new products and services to your clients and users? It does not matter what your goals are, we help you analyze them and pin-point the challenges to overcome in order for you to grow.',
            }}
            image={{content: Analysis, pos: 'left'}}
          />
          <Section
            text={{
              title: 'Specialized teams',
              content:
                'Brilliant solutions require brilliant people. We provide teams of professionals specifically forged to work on your projects and bring the most value to your company. Management, design, operations, IT, analysts,... Our experts at your service.',
            }}
            image={{content: Team, pos: 'right'}}
          />
          <Section
            text={{
              title: 'Cutting-edge technology',
              content:
                'Businesses cannot grow if they lack the right tools. We bring full digital transformation to you and your teams, powered by the latest technological advances. From migrating to the Cloud to integrating AI on your systems, we can take care of it.',
            }}
            image={{content: Technology, pos: 'left'}}
          />
          <Section
            text={{
              title: 'Cost-effective solutions',
              content:
                'Know what you are getting, and when you are getting it. No unexpected costs, no surprises.',
            }}
            image={{content: Cost, pos: 'right'}}
          />
        </div>
      </ServicesStyle>
    );
  }
}

export default Services;
