import {Component} from 'react';

import Header from 'components';

import MainStyle from './Main.style';
import TopSection from './components/topSection';

class Main extends Component {
  render() {
    return (
      <MainStyle>
        <div className="header">
          <Header />
        </div>
        <div className="content">
          <TopSection />
        </div>
      </MainStyle>
    );
  }
}

export default Main;
