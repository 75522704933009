import styled from 'styled-components';

const NotFoundStyle = styled.div`
  .header {
    position: sticky;
    top: 0px;
    z-index: 1;
  }
  .content {
    padding-top: 64px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }
`;

export default NotFoundStyle;
