import styled from 'styled-components';

interface ButtonStyleProps {
  coloringos: 'direct' | 'inverse';
}

const ButtonStyle = styled.button<ButtonStyleProps>`
  min-width: 180px;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background: ${({coloringos}) =>
    coloringos === 'direct' ? '#2c3b4c' : '#7effe3'};
  font-size: 20px;
  color: ${({coloringos}) =>
    coloringos === 'inverse' ? '#2c3b4c' : '#7effe3'};
  transition: all 0.15s ease;

  :hover {
    background: ${({coloringos}) =>
      coloringos === 'direct' ? '#355763' : '#C9FFF3'};
    color: ${({coloringos}) =>
      coloringos === 'inverse' ? '#2c3b4c' : '#7effe3'};
    cursor: pointer;
  }
`;

export default ButtonStyle;
