import styled from 'styled-components';

interface SectionStyleProps {
  imagePos: 'left' | 'right';
}

const SectionStyle = styled.div<SectionStyleProps>`
  .content {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 20%;
    padding-right: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .text {
    text-align: justify;
    opacity: 0;
    transform: ${({imagePos}) => {
      const sign = imagePos === 'left' ? 1 : -1;
      return `translateX(${sign * 10}vw)`;
    }};
    visibility: hidden;
    transition: opacity 1.4s ease-out, transform 0.7s ease-out;
    will-change: opacity, visibility;
  }

  .text.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

  .title {
    color: #26b294;
    font-size: 30px;
    margin-bottom: 10px;
    text-align: ${({imagePos}) => imagePos};
  }

  .image {
    opacity: 0;
    transform: ${({imagePos}) => {
      const sign = imagePos === 'left' ? -1 : 1;
      return `translateX(${sign * 10}vw)`;
    }};
    visibility: hidden;
    transition: opacity 1.4s ease-out, transform 0.7s ease-out;
    transition-delay: 0.2s;
    will-change: opacity, visibility;
  }

  .image.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
`;

export default SectionStyle;
