import {Component} from 'react';

import Header from 'components';

import ContactStyle from './Contact.style';

class Contact extends Component {
  render() {
    return (
      <ContactStyle>
        <div className="header">
          <Header />
        </div>
        <div className="cabecita">Nice to meet you, how can we help?</div>
        <div className="content">
          <div style={{width: '540px', textAlign: 'center', marginTop: '40px'}}>
            At Dualimind, we provide solutions to our clients, so they can get
            done what they need to see their businesses grow.
          </div>
          <div style={{width: '540px', textAlign: 'center', marginTop: '40px'}}>
            Do you want to know more about it? Let us know what's on your mind!
          </div>
          <div
            style={{
              width: '540px',
              textAlign: 'center',
              fontWeight: 'bold',
              color: '#017c62',
              marginTop: '40px',
            }}
          >
            <a
              style={{color: 'inherit', textDecoration: 'inherit'}}
              href="mailto:contact@dualimind.com"
            >
              contact@dualimind.com
            </a>
          </div>
        </div>
      </ContactStyle>
    );
  }
}

export default Contact;
