import React, {Component, FunctionComponent, SVGProps} from 'react';

import SectionStyle from './Section.style';

interface SectionProps {
  text: {title: string; content: string};
  image: {
    content: FunctionComponent<SVGProps<SVGSVGElement>>;
    pos: 'left' | 'right';
  };
}

class Section extends Component<SectionProps, {visible: boolean}> {
  state = {visible: false};
  ref: React.RefObject<HTMLDivElement>;

  constructor(props: SectionProps) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(({isIntersecting}) =>
        this.setState({visible: this.state.visible || isIntersecting})
      );
    });

    observer.observe(this.ref.current!);
  }

  render() {
    const {text, image} = this.props;

    const textClassName = `text ${this.state.visible ? 'is-visible' : ''}`;
    const imageClassName = `image ${this.state.visible ? 'is-visible' : ''}`;
    const elements = [
      <div id="text" key="text" className={textClassName}>
        <div className="title">{text.title}</div>
        {text.content}
      </div>,
      <div key="img" className={imageClassName}>
        <image.content
          style={{width: '300px', height: 'auto', margin: '30px'}}
        />
      </div>,
    ];
    if (image.pos === 'left') elements.reverse();

    return (
      <SectionStyle ref={this.ref} imagePos={image.pos}>
        <div className="content">{elements}</div>
      </SectionStyle>
    );
  }
}

export default Section;
