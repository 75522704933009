import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Main from 'views/main';
import Services from 'views/services';
import Contact from 'views/contact';
import NotFound from 'views/notFound';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
