import styled from 'styled-components';

const HeaderStyle = styled.div`
  .header {
    height: 100px;
    background: linear-gradient(#ffffff 96%, #00000000);
    display: flex;
    justify-content: center;
  }

  .header-content {
    min-width: 75%;
    display: flex;
    justify-content: space-between;
  }

  .logo {
    margin: 25px;
    background: none;
    border: none;
  }
  .logo:hover {
    cursor: pointer;
  }

  .navigation {
    margin: 25px;
    display: flex;
    justify-content: space-between;
  }

  .navigation-element {
    font-size: 18px;
    margin-left: 25px;
    margin-right: 25px;
    align-self: center;
    background: none;
    border: none;
    transition: all 0.15s ease;
  }

  .navigation-element:hover {
    transform: translateY(-4px);
    cursor: pointer;
    color: #26b294;
  }
`;

export default HeaderStyle;
