import styled from 'styled-components';

const MainStyle = styled.div`
  .header {
    position: sticky;
    top: 0px;
    z-index: 1;
  }
  .content {
  }
`;

export default MainStyle;
