import styled from 'styled-components';

const ServicesStyle = styled.div`
  .header {
    position: sticky;
    top: 0px;
    z-index: 1;
  }

  .cabecita {
    color: #2c3b4c;
    text-align: center;
    font-size: 52px;
    font-weight: 400;
    margin-top: 50px;
  }

  .content {
  }
`;

export default ServicesStyle;
