import {Component} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';

import TopSectionStyle from './TopSection.style';
import Button from 'components/button';

interface TopSectionProps {
  navigate: NavigateFunction;
}

class TopSection extends Component<TopSectionProps> {
  render() {
    return (
      <TopSectionStyle>
        <img className="background" src="images/background.png"></img>
        <div className="content">
          <div className="slogan">NO TIME FOR COOL SLOGANS</div>
          <div className="subslogan">We are focused on delivering</div>
          <div></div>
          <div className="buttons">
            <div style={{marginRight: '10px'}}>
              <Button
                onClick={() => {
                  this.props.navigate('/services');
                }}
                text="Important stuff"
                coloringos="inverse"
              />
            </div>
            <Button
              onClick={() => {
                this.props.navigate('/contact');
              }}
              text="Help me now"
              coloringos="direct"
            />
          </div>
        </div>
      </TopSectionStyle>
    );
  }
}

function NavTopSection() {
  const navigate = useNavigate();
  return <TopSection navigate={navigate} />;
}

export default NavTopSection;
