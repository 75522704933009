import {Component} from 'react';

import ButtonStyle from './Button.style';

interface ButtonProps {
  text: string;
  onClick: () => void;
  coloringos: 'direct' | 'inverse';
}

class Button extends Component<ButtonProps> {
  render() {
    return (
      <ButtonStyle
        coloringos={this.props.coloringos}
        onClick={this.props.onClick}
      >
        {this.props.text}
      </ButtonStyle>
    );
  }
}

export default Button;
