import {Component} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';

import {ReactComponent as Logo} from './images/logo.svg';
import HeaderStyle from './Header.style';

interface HeaderProps {
  navigate: NavigateFunction;
}

class Header extends Component<HeaderProps> {
  render() {
    return (
      <HeaderStyle>
        <div className="header">
          <div className="header-content">
            <div className="logo" onClick={() => this.props.navigate('/')}>
              <Logo style={{height: '100%', width: '100%'}} />
            </div>
            <div className="navigation">
              <button
                className="navigation-element"
                onClick={() => this.props.navigate('/services')}
              >
                Services
              </button>
              <button
                className="navigation-element"
                onClick={() => this.props.navigate('/contact')}
              >
                Contact
              </button>
            </div>
          </div>
        </div>
      </HeaderStyle>
    );
  }
}

function NavHeader() {
  const navigate = useNavigate();
  return <Header navigate={navigate} />;
}

export default NavHeader;
